<template>
  <div id="comparison">
    <div class="columns">
      <div class="column is-9-desktop">
        <h2 class="is-size-5 has-text-weight-bold has-text-dark mb-4">{{ $t('comparison.comparison') }}</h2>

        <comparison-chart ref="comparison-chart"/>
      </div>

      <div class="column is-3-desktop">
        <h3 class="is-size-6 has-text-grey mb-3 mt-3">My Templates</h3>

        <div v-if="template && template.data && template.data.length">
          <div class="card mb-3" v-for="(item, key) in template.data" :key="key">
            <div class="card-content">
              <div v-for="(parameter, k) in item" :key="k">
                <p class="mb-2">{{ parameter.selected_parameter.label }}</p>
                <p class="has-text-secondary is-size-7">
                  {{ parameter.block.block_name }} - {{ parameter.pond.name }} - {{ parameter.cycle.cycle_name }}</p>

                <div class="is-divider my-3"></div>
              </div>

              <div class="is-flex is-justify-content-space-between is-align-items-center">
                <b-button type="is-info is-light" class="p-0" @click="loadTemplate(item)">Load Template</b-button>

                <b-button type="is-danger is-light" class="px-4" @click="removeTemplate(key)">
                  <b-icon icon="trash-can-outline" size="is-small"/>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <p class="my-3">{{ $t('no') }} Data</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ComparisonChart from "@/components/Farm/Comparison/ComparisonChart";

export default {
  name: "Comparison",
  components: {
    ComparisonChart,
  },
  computed: {
    farm_id() {
      return this.selectedFarm.farm_id
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },

    ...mapGetters('comparison', [
      'typeOptions',
      'template'
    ]),
  },
  methods: {
    ...mapActions('comparison', [
      'addTemplate',
      'removeTemplate',
      'compare',
    ]),
    loadTemplate (item) {
      this.$refs['comparison-chart'].loadTemplate(item)
    },
  }
}
</script>
